import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthContextService } from '../../services/auth-context.service';

@Component({
  selector: 'x-current-user',
  templateUrl: './current-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  styleUrls: ['current-user.component.scss'],
  host: {
    class: 'x-current-user',
  },
})
export class CurrentUserComponent {
  constructor(public authContext: AuthContextService) {}

  currentUser$ = this.authContext.user();
}
