import { Injectable } from '@angular/core';
import { IDataTableViewState, IDataTableViewStorage } from '@x/common/data';
import { LocalStorage, SessionStorage } from '@x/common/storage';
import { makeId } from '@x/common/utils';
import { VariableService } from '@x/ecommerce/domain-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataViewManagerService implements IDataTableViewStorage {
  private _globalViews$ = new BehaviorSubject<Record<string, IDataTableViewState[]>>({});

  constructor(
    private readonly variableService: VariableService,
    private readonly localStorage: LocalStorage,
    private readonly sessionStorage: SessionStorage,
  ) {}

  watch(repository: string): Observable<IDataTableViewState[]> {
    return this.variableService.fetchGlobalVariableArray(`${repository}.`).pipe(
      tap((arr) => {
        const views = arr.map((v) => v.value);
        this._globalViews$.next({
          ...this._globalViews$.getValue(),
          [repository]: this.sortViews(views),
        });
      }),
      switchMap((v) => this._globalViews$.pipe(map((g) => g[repository] ?? []))),
    );
  }

  create(
    repository: string,
    view: Omit<IDataTableViewState, 'id'>,
  ): Observable<IDataTableViewState> {
    const id = makeId(32);
    const newView = { ...view, id };
    return this.variableService.setGlobalVariable(`${repository}.${id}`, newView).pipe(
      map((v) => v.value),
      tap((view) => this.patchView(repository, view)),
    );
  }

  update(repository: string, view: IDataTableViewState): Observable<IDataTableViewState> {
    return this.variableService.setGlobalVariable(`${repository}.${view.id}`, view).pipe(
      map((v) => v.value),
      tap((view) => this.patchView(repository, view)),
    );
  }

  saveCurrentState(repository: string, state: IDataTableViewState<any, any, any>): void {
    this.localStorage.setItem(this.getLocalStorageKey(repository), state);
  }

  restoreCurrentState(repository: string): IDataTableViewState<any, any, any> | null {
    return this.localStorage.getItem(this.getLocalStorageKey(repository)) ?? null;
  }

  private getLocalStorageKey(repository: string) {
    return `data_view.${repository}`;
  }

  private patchView(repository: string, view: IDataTableViewState) {
    const value = (this._globalViews$.getValue()[repository] ?? []).filter((v) => v.id !== view.id);
    value.push(view);

    this._globalViews$.next({
      ...this._globalViews$.getValue(),
      [repository]: this.sortViews(value),
    });
  }

  private sortViews(views: IDataTableViewState[]) {
    return views.sort((a, b) => {
      if ((a.title ?? a.id) > (b.title ?? b.id)) {
        return 1;
      }
      if ((a.title ?? a.id) < (b.title ?? b.id)) {
        return -1;
      }
      return 0;
    });
  }
}
