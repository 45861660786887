<ng-container *ngIf="currentUser$ | async as currentUser">
  <div class="user-pill" [matMenuTriggerFor]="userMenu" matRipple>
    <fa-icon icon="circle-user" size="lg"></fa-icon>
    <div class="user-info">
      <div class="user-name">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
      <div class="user-email">{{ currentUser.email }}</div>
    </div>
  </div>

  <mat-menu #userMenu="matMenu" xPosition="before">
    <button mat-menu-item routerLink="/auth/forgot-password">
      <fa-icon icon="lock"></fa-icon>
      <span>Forgot Password</span>
    </button>
    <button mat-menu-item [routerLink]="['users', 'detail', currentUser.id]">
      <fa-icon icon="user"></fa-icon>
      <span>My User Detail</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="authContext.logout()">
      <fa-icon icon="sign-out-alt"></fa-icon>
      <span>Log Out</span>
    </button>
  </mat-menu>
</ng-container>
