import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { InjectLogger, Logger } from '@x/common/log';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { AuthContextService } from '../services/auth-context.service';

@Injectable()
export class AuthGuard  {
  @InjectLogger()
  log: Logger;

  constructor(private router: Router, private authContext: AuthContextService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    if (!this.authContext.isAuthenticated) {
      this.redirectToLogin(state);
      return false;
    }

    if (this.authContext.currentUser) {
      return true;
    }

    return this.authContext.user().pipe(
      debounceTime(1000),
      map((user) => {
        if (!user) {
          this.redirectToLogin(state);
          return false;
        }

        return true;
      }),
    );
  }

  private redirectToLogin(state: RouterStateSnapshot) {
    this.log.warn(`User not authenticated for route '${state.url}', redirecting to login`);
    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url,
      },
    });
  }
}
