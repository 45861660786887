import { Component, HostBinding, Inject } from '@angular/core';
import { AdminModuleConfig, ADMIN_MODULE_CONFIG } from '@x/ecommerce-admin/app/admin/admin.config';

@Component({
  selector: 'x-auth-layout',
  templateUrl: 'auth-layout.component.html',
  host: {
    class: 'x-auth-layout',
  },
})
export class AuthLayoutComponent {
  constructor(
    @Inject(ADMIN_MODULE_CONFIG)
    public readonly adminConfig: AdminModuleConfig,
  ) {}

  @HostBinding('style.background-image')
  backgroundImage = `url(${this.adminConfig.backgroundImageUrl})`;
}
